import Cameron from "../../assets/testimonial/cameron.png"
import Esther from "../../assets/testimonial/esther.png"
import Devon from "../../assets/testimonial/devon.png"


export default function Testimonials() {
    return (
        <div className="testimonial-section text-center pb-5">
            <h1 className="yello-text mb-3">Testimonial</h1>
            <p className="white-text text17 pb-4">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            </p>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-4 col-md-6 mb-4">
                        <div className="testimonial-card p-4 rounded">
                            <div className="d-flex align-items-center mb-3">
                                <div>
                                    <img src={Cameron} alt="Cameron Williamson" className="rounded-circle-testimonial me-3"
                                />
                                </div>
                                
                                <div className="testimonial-title justify-content-center align-items-center pt-3">
                                    <h6 className="mb-0 text-start pb-1">Sri Vidya</h6>
                                    <p className="text-start">Jeya Nagar</p>
                                </div>
                                <span className="ms-auto text-muted">
                                    <i class="bi bi-quote"></i>
                                </span>
                            </div>
                            <p className="text-dark text-start">
                                “Ranav Group's platform helped me compare properties with ease and estimate my loan options. The process was seamless.”
                            </p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 mb-4">
                        <div className="testimonial-card p-4 rounded">
                            <div className="d-flex align-items-center mb-3">
                                <div>
                                    <img src={Esther} alt="Cameron Williamson" className="rounded-circle-testimonial me-3"
                                />
                                </div>
                                
                                <div className="testimonial-title justify-content-center align-items-center pt-3">
                                    <h6 className="mb-0 text-start pb-1">Chettan Reddy</h6>
                                    <p className="text-start">Marathahalli</p>
                                </div>
                                <span className="ms-auto text-muted">
                                    <i class="bi bi-quote"></i>
                                </span>
                            </div>
                            <p className="text-dark text-start">
                                “I am amazed by the professionalism of the team at Ranav. They truly understood our needs and delivered beyond our expectations.”
                            </p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 mb-4">
                        <div className="testimonial-card p-4 rounded">
                            <div className="d-flex align-items-center mb-3">
                                <div>
                                    <img src={Devon} alt="Cameron Williamson" className="rounded-circle-testimonial me-3"
                                />
                                </div>
                                
                                <div className="testimonial-title justify-content-center align-items-center pt-3">
                                    <h6 className="mb-0 text-start pb-1">Sathya</h6>
                                    <p className="text-start">Kundhalahalli</p>
                                </div>
                                <span className="ms-auto text-muted">
                                    <i class="bi bi-quote"></i>
                                </span>
                            </div>
                            <p className="text-dark text-start">
                                “Ranav Group offers excellent property options and unparalleled support throughout the buying process. I highly recommend them.”
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
