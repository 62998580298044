import property from "../../assets/icons/property.png";
import villas from "../../assets/icons/villas.png";
import trust from "../../assets/icons//trust.png";

export default function KeyFactorOfRANAV(){
    return(
        <>
        <div>
        <div className="text-center">
          <p className="askd-txt m-0">Key Factor of RANAV</p>
          <span className="text-white m-0">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          </span>
        </div>
        <div className="container-fluid leader-vmvsec ">
          <div className="row px-5">
            <div className="col-lg-4 px-5">
              <div className="vmv-sec">
                <img src={property} alt="" style={{ width: "40px" }} />
                <p className="vmv">Vision</p>
                <span className="vmv-des">
                  To drive innovation in construction and emerge as a reliable
                  real estate entity that builds healthy and harmonious
                  residential communities and vibrant
                </span>
              </div>
            </div>
            <div className="col-lg-4 px-5">
              <div className="vmv-sec">
                <img src={villas} alt="" style={{ width: "40px" }} />
                <p className="vmv">Mision</p>
                <span className="vmv-des">
                  To create positive change by crafting worldchanging
                  residential and commercial projects, and committing to keeping
                  our promises of affordability in pricing, transparency in
                  transactions and on-time delivery of projects.
                </span>
              </div>
            </div>
            <div className="col-lg-4 px-5">
              <div className="vmv-sec">
                <img src={trust} alt="" style={{ width: "40px" }} />
                <p className="vmv">Value</p>
                <span className="vmv-des">
                  At Ranav Projects: we put quality and innovation at the heart
                  of all our work related actions and efforts. As a results
                  driven team ably led by an aspirational and focused founder
                  and CEO, we are united by our purpose of delivering
                  exceptional outcomes at less costs and in lesser time.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
        </>
    )
}