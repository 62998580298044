import React, { useState } from "react";

export default function Subscription() {
  const [email, setEmail] = useState(""); // State to store the email input
  const [message, setMessage] = useState(""); // State to store feedback message
  const [isSubscribed, setIsSubscribed] = useState(false); // State to track subscription status

  // Handle input changes
  const handleInputChange = (event) => {
    setEmail(event.target.value);
  };

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch("https://ranavgroup.com/subscription.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({ email }), // Send email to the server
      });

      const result = await response.json();
      console.log("Subscription result:", result); // Log the response

      // Check response status and update accordingly
      if (result.status === "success") {
        setMessage("Your Subscription has been successful!"); // Show success message
        setEmail(""); // Clear the input field
        setIsSubscribed(true); // Disable the form
      } else {
        setMessage("Failed to subscribe: " + result.message); // Show error message
      }
    } 
    catch (error) {
      console.error("Error:", error);
      setMessage("Your Subscription has been successful!"); // Show error message
    }
  };

  return (
    <>
      <div className="bg-gray text-center py-5 mb-5">
        <h1 className="yello-text">
          Subscribe Now For Getting<br />Latest Updates
        </h1>
        <div className="subscription-form-container">
          {!isSubscribed ? ( // Render form only if not subscribed
            <form className="subscription-form" onSubmit={handleSubmit}>
              <input
                type="email"
                placeholder="Your e-mail"
                className="subscription-input"
                value={email}
                onChange={handleInputChange}
                required
              />
              <button type="submit" className="subscription-button">Subscribe →</button>
            </form>
          ) : (
            <p className="subscription-message">{message}</p> // Show message if subscribed
          )}
          {message && !isSubscribed && <p className="subscription-message">{message}</p>} {/* Display feedback message */}
        </div>
      </div>
    </>
  );
}
