import tall1 from "../../assets/grid-images/tall-1.png";
import tall2 from "../../assets/grid-images/tall-2.jpg";
import tall3 from "../../assets/grid-images/tall-3.jpg";
import tall4 from "../../assets/grid-images/tall-4.jpg";
import tall5 from "../../assets/grid-images/tall-5.jpg";
import tall6 from "../../assets/grid-images/tall-6.jpg";

export default function RanavTeam(){
    return(
    <>
    <div className="grid-img-sec">
        <div className="text-center mb-20">
          <p className="askd-txt m-0">Meet our team</p>
          <span className="text-white m-0 ">
            Our team is made up of 6 professional talented architects and
            BIM/CAD technicians who contribute immensely to the work we do.
          </span>
        </div>
        <div className="grid-wrapper">
          <div className="tall">
            <img src={tall1} alt="" />
          </div>
          <div className="wide">
            <img src={tall2} alt="" />
          </div>
          <div className="tall">
            <img src={tall3} alt="" />
          </div>
          <div className="">
            <img src={tall4} alt="" />
          </div>
          <div className="">
            <img src={tall5} alt="" />
          </div>
          <div className="">
            <img src={tall6} alt="" />
          </div>
        </div>
      </div>
    </>
    )
}