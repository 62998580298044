import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'bootstrap-icons/font/bootstrap-icons.css';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import { CSSTransition } from "react-transition-group";


import NavBbar from './navbar';

export default function Header() {
    return (
    <NavBbar />
    );
}
