import React, { useEffect } from 'react';
import GLightbox from 'glightbox';
import 'glightbox/dist/css/glightbox.min.css';
import Masonry from 'react-masonry-css';

// Import images from local assets
import Image1 from "../../assets/gallery/image1.png";
import Image2 from "../../assets/gallery/image2.png";
import Image3 from "../../assets/gallery/image3.png";
import Image4 from "../../assets/gallery/image4.png";
import Image5 from "../../assets/gallery/image5.png";
import Image6 from "../../assets/gallery/image6.png";
import Image7 from "../../assets/gallery/image7.png";
import Image8 from "../../assets/gallery/image8.png";
import Image9 from "../../assets/gallery/img9.png";
import Image10 from "../../assets/gallery/img10.png";
import Image11 from "../../assets/gallery/img11.png";

export default function GalleryImg() {
  useEffect(() => {
    // Initialize GLightbox
    const lightbox = GLightbox({
      selector: '.glightbox',
    });

    return () => {
      // Destroy lightbox when component unmounts
      lightbox.destroy();
    };
  }, []);

  const images = [
    { id: 1, thumb: Image1, full: Image1 },
    { id: 2, thumb: Image2, full: Image2 },
    { id: 3, thumb: Image3, full: Image3 },
    { id: 4, thumb: Image4, full: Image4 },
    { id: 5, thumb: Image5, full: Image5 },
    { id: 6, thumb: Image6, full: Image6 },
    { id: 7, thumb: Image7, full: Image7 },
    { id: 8, thumb: Image8, full: Image8 },
    { id: 9, thumb: Image9, full: Image9 },
    { id: 10, thumb: Image10, full: Image10 },
    { id: 11, thumb: Image11, full: Image11 },
  ];

  // Masonry breakpoint columns configuration
  const breakpointColumnsObj = {
    default: 3, // 3 columns on larger screens
    1100: 2,    // 2 columns on medium screens
    700: 1      // 1 column on smaller screens
  };

  return (
    <div className="container pt-5">
      <div className="text-center py-5">
        <p className="askd-txt m-0">Key Factor of RANAV</p>
        <span className="text-white m-0">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit.
        </span>
      </div>

      {/* Masonry Layout */}
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {images.map((image) => (
          <div key={image.id} className="mb-4">
            <a href={image.full} className="glightbox">
              <img src={image.thumb} className="img-fluid" alt={`Image ${image.id}`} />
            </a>
          </div>
        ))}
      </Masonry>
    </div>
  );
}
