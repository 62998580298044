import LeaderBannerImage from '../../assets/banners/leader-banner.png';

export default function LeaderBanner(){
    return(
        <>
        <div className="bg-brown text-white row pt-2">
            <div className='col-1'></div>
        <div className="col-md-3 d-flex align-items-center mob-center home-banner-text pt-5">
            <div>
            <h6>Our Team</h6>
            <h1 className='pt-3 pb-3'>What Makes<br /><span className='yello-text'>Ranav</span><br />Standout?</h1>
            {/* <p>From budget-friendly options to limited-time discount offers,<br />we help you find the perfect space to call Leader.</p> */}
            </div>
            
        </div>
          
        
        <div className="col-md-8 pt-5 home_banner_image">
          <img className='' src={LeaderBannerImage} />
        </div>
      </div>
        </>
    )
}