export default function AboutContent(){
    return(
        <>
            <sectio className="text-center container about-section">
                <div className="container py-5">
                    <h1 className="yello-text pb-2">About Us</h1>
                    <p className="white-text">At Ranav Group, we believe that owning a home, office, or plot is more than just a transaction—it's a symbol of freedom, a personal choice that reflects your aspirations. We build homes, villas, commercial spaces, and offer premium plots in prime locations across Bangalore. Our mission is to rank among the top construction companies in Bangalore by offering quality, luxury, and reliable developments—whether it's gated communities, open plots, or commercial projects.</p>
                </div>
            </sectio>
        </>
    )
}