import BestVillaStatic from "../../assets/Villa_best.png"
import BestSlide1 from "../../assets/best_slide1.png"
import React, { useState } from 'react';
import VideoThumb from "../../assets/bg-video.jpg.png";

import Slider from "react-slick";

export default function BestProperties() {

    // Slick slider settings
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 1000,
    };
    const [videoVisible, setVideoVisible] = useState(false);

    const handlePlayClick = () => {
        setVideoVisible(true);
    };
    return (
        <div className="best-properties-section text-center py-5">
            <h1 className="yello-text mb-3">Best Properties</h1>
            <p className="white-text text17 pb-4">
                Explore over 280+ properties across Bangalore to find your dream home.
            </p>
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-12 mb-4">
                        <div className="best-property-image">
                            <img
                                src={BestVillaStatic}
                                alt="Property Image"
                                className="img-fluid rounded"
                            />
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12 pe-0">
                        {/* Full-width Image/Slider */}
                        <div className="row mb-4">
                            <div className="col-12">
                                <Slider {...settings}>
                                    <div>
                                        <img
                                            src={BestSlide1}
                                            alt="Interior 1"
                                            className="img-fluid rounded"
                                        />
                                    </div>
                                    <div>
                                        <img
                                            src={BestSlide1}
                                            alt="Interior 2"
                                            className="img-fluid rounded"
                                        />
                                    </div>
                                </Slider>
                            </div>
                        </div>

                        <div className="row">
                            {/*Video/Image */}
                            <div className="col-lg-6 col-md-12 mb-4">
                                <div className="embed-responsive embed-responsive-16by9">
                                    {!videoVisible ? (
                            <div className="video-thumbnail position-relative">
                                <img
                                    src={VideoThumb}
                                    alt="Video Thumbnail"
                                    className="img-fluid rounded"
                                />
                                <button
                                    className="play-button"
                                    onClick={handlePlayClick}
                                >
                                    <i className="bi bi-play-circle-fill" style={{ fontSize: "3rem", color: "#fff" }}></i>
                                </button>
                            </div>
                                ) : (
                                    <div className="embed-responsive embed-responsive-16by9">
                                        <iframe
                                            width="100%"
                                            height="auto"
                                            src="https://www.youtube.com/embed/i5l3e5RwkIg?autoplay=1"
                                            title="YouTube video"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowFullScreen
                                        ></iframe>
                                    </div>
                                )}
                                </div>
                            </div>

                            {/* 280 + properties Info Card */}
                            <div className="col-lg-6 col-md-12 d-flex flex-column justify-content-center">
                                <div className="p-4 properties-card rounded text-start">
                                    <h3 className="">280+</h3>
                                    <p>Properties</p>
                                    <p>Explore our wide variety of properties to find your dream home today.</p>
                                    <div className="d-flex justify-content-between">
                                        <button className="btn download btn-outline-dark">View More</button>
                                        <button className="btn download btn-outline-dark">Download PDF</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
