import Header from "../components/header";
import Subscription from "../components/subscription";
import Footer from "../components/footer";
import leftlogo from "../assets/projects/ranav-heaven-logo.jpg";
import aura_ranav_logo from "../assets/projects/aura_ranav_logo.jpg";
import adhira_ranav_logo from "../assets/projects/adhira_ranav_logo.jpg";
import mystic_heights_logo from "../assets/projects/mystic_heights_logo.jpg";
import vallabha_ranav_logo from "../assets/projects/vallabha_ranav_logo.jpg";
import ranav_landmark_logo from "../assets/projects/ranav_landmark_logo.jpg";
import tranqul_ranav_logo from "../assets/projects/tranqul_ranav_logo.jpg";
import akira_ranav_logo from "../assets/projects/akira_ranav_logo.jpg";
import eco_urbania_ranav_logo from "../assets/projects/eco_urbania_ranav_logo.jpg";
import project1 from "../assets/projects/ranav-heaven.jpg";
import project2 from "../assets/projects/aura_ranav.jpg";
import project3 from "../assets/projects/adhira_ranav.jpg";
import project4 from "../assets/projects/mystic_heights.jpg";
import project5 from "../assets/projects/vallabha_ranav.jpg";
import project6 from "../assets/projects/ranav_landmark.jpg";
import project7 from "../assets/projects/tranqul_ranav.jpg";
import project8 from "../assets/projects/akira_ranav.jpg";
import project9 from "../assets/projects/eco_urbania_ranav.jpg";
import area from "../assets/projects/area.jpg";
import rera from "../assets/projects/rera-project.jpg";
import villas from "../assets/projects/villas.jpg";
import ranavstamp from "../assets/projects/ranav-stamp.jpg";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";

export default function Projects() {
  return (
    <>
      <Header />
      <div className="projects">
        <a href="#project1">Project-1</a>
        <a href="#project2">Project-2</a>
        <a href="#project3">Project-3</a>
        <a href="#project4">Project-4</a>
        <a href="#project5">Project-5</a>
        <a href="#project6">Project-6</a>
        <a href="#project7">Project-7</a>
        <a href="#project8">Project-8</a>
        <a href="#project9">Project-9</a>
      </div>
      <h2>Projects page</h2>
      <div className="main-content" id="project1">
        <div className="left-content">
          <div className="">
            <div className="left-logo">
              <img src={leftlogo} alt="" />
            </div>
            <div className="left-sub-content">
              <div className="sub-icon">
                <i class="bi bi-geo-fill"></i>
              </div>
              <div className="sub-title">
                <p>
                  Soukya Road,<br></br> WhiteField Bengaluru
                </p>
              </div>
            </div>
            <div className="left-sub-content">
              <div className="sub-icon">
                <img src={area} style={{ width: "30px" }} alt="area" />
              </div>
              <div className="sub-title">
                <p>2.1 Acres Total Project Area</p>
              </div>
            </div>
            <div className="left-sub-content">
              <div className="sub-icon">
                <img src={rera} style={{ width: "40px" }} alt="area" />
              </div>
              <div className="sub-title">
                <p>RERA PROJECT</p>
              </div>
            </div>
            <div className="left-sub-content">
              <div className="sub-icon">
                <img src={villas} style={{ width: "40px" }} alt="area" />
              </div>
              <div className="sub-title">
                <p>35 Luxurious Villas</p>
              </div>
            </div>
            <div className="stamp">
              <img src={ranavstamp} style={{ width: "150px" }} alt="" />
            </div>
            <div className="foot-title-left">
              <p>LUXUARY VILLAS</p>
            </div>
          </div>
        </div>
        <div className="right-content">
          <img src={project1} alt="" />
        </div>
      </div>
      <div className="main-content" id="project2">
        <div className="left-content">
          <div className="">
            <div className="left-logo">
              <img src={aura_ranav_logo} alt="" />
            </div>
            <div className="left-sub-content">
              <div className="sub-icon">
                <i class="bi bi-geo-fill"></i>
              </div>
              <div className="sub-title">
                <p>
                  CHANNASANDRA,<br></br> WhiteField Bengaluru
                </p>
              </div>
            </div>
            <div className="left-sub-content">
              <div className="sub-icon">
                <img src={area} style={{ width: "30px" }} alt="area" />
              </div>
              <div className="sub-title">
                <p>20 Acres Total Project Area</p>
              </div>
            </div>
            <div className="left-sub-content">
              <div className="sub-icon">
                <img src={rera} style={{ width: "40px" }} alt="area" />
              </div>
              <div className="sub-title">
                <p>RERA PROJECT</p>
              </div>
            </div>
            <div className="left-sub-content">
              <div className="sub-icon">
                <img src={villas} style={{ width: "40px" }} alt="area" />
              </div>
              <div className="sub-title">
                <p>Bali Style Luxurious Villas</p>
              </div>
            </div>
            <div className="stamp">
              <img src={ranavstamp} style={{ width: "150px" }} alt="" />
            </div>
            <div className="foot-title-left">
              <p>LUXUARY VILLAS</p>
            </div>
          </div>
        </div>
        <div className="right-content">
          <img src={project2} alt="" />
        </div>
      </div>
      <div className="main-content" id="project3">
        <div className="left-content">
          <div className="">
            <div className="left-logo">
              <img src={adhira_ranav_logo} alt="" />
            </div>
            <div className="left-sub-content">
              <div className="sub-icon">
                <i class="bi bi-geo-fill"></i>
              </div>
              <div className="sub-title">
                <p>
                  KORALURU,<br></br> WhiteField Bengaluru
                </p>
              </div>
            </div>
            <div className="left-sub-content">
              <div className="sub-icon">
                <img src={area} style={{ width: "30px" }} alt="area" />
              </div>
              <div className="sub-title">
                <p>4 Acres Total Project Area</p>
              </div>
            </div>
            <div className="left-sub-content">
              <div className="sub-icon">
                <img src={rera} style={{ width: "40px" }} alt="area" />
              </div>
              <div className="sub-title">
                <p>RERA PROJECT</p>
              </div>
            </div>
            <div className="left-sub-content">
              <div className="sub-icon">
                <img src={villas} style={{ width: "40px" }} alt="area" />
              </div>
              <div className="sub-title">
                <p>14 Floors Luxurious Apartments</p>
              </div>
            </div>
            <div className="stamp">
              <img src={ranavstamp} style={{ width: "150px" }} alt="" />
            </div>
            <div className="foot-title-left">
              <p>LUXUARY APARTMENTS</p>
            </div>
          </div>
        </div>
        <div className="right-content">
          <img src={project3} alt="" />
        </div>
      </div>
      <div className="main-content" id="project4">
        <div className="left-content">
          <div className="">
            <div className="left-logo">
              <img src={mystic_heights_logo} alt="" />
            </div>
            <div className="left-sub-content">
              <div className="sub-icon">
                <i class="bi bi-geo-fill"></i>
              </div>
              <div className="sub-title">
                <p>
                  HASAKOTE,<br></br>Bengaluru
                </p>
              </div>
            </div>
            <div className="left-sub-content">
              <div className="sub-icon">
                <img src={area} style={{ width: "30px" }} alt="area" />
              </div>
              <div className="sub-title">
                <p>10 Acres Total Project Area</p>
              </div>
            </div>
            <div className="left-sub-content">
              <div className="sub-icon">
                <img src={rera} style={{ width: "40px" }} alt="area" />
              </div>
              <div className="sub-title">
                <p>RERA PROJECT</p>
              </div>
            </div>
            <div className="left-sub-content">
              <div className="sub-icon">
                <img src={villas} style={{ width: "40px" }} alt="area" />
              </div>
              <div className="sub-title">
                <p>14 Floors Luxurious Apartments</p>
              </div>
            </div>
            <div className="stamp">
              <img src={ranavstamp} style={{ width: "150px" }} alt="" />
            </div>
            <div className="foot-title-left">
              <p>LUXUARY APARTMENTS</p>
            </div>
          </div>
        </div>
        <div className="right-content">
          <img src={project4} alt="" />
        </div>
      </div>
      <div className="main-content" id="project5">
        <div className="left-content">
          <div className="">
            <div className="left-logo">
              <img src={vallabha_ranav_logo} alt="" />
            </div>
            <div className="left-sub-content">
              <div className="sub-icon">
                <i class="bi bi-geo-fill"></i>
              </div>
              <div className="sub-title">
                <p>
                  Soukya Road,<br></br> WhiteField Bengaluru
                </p>
              </div>
            </div>
            <div className="left-sub-content">
              <div className="sub-icon">
                <img src={area} style={{ width: "30px" }} alt="area" />
              </div>
              <div className="sub-title">
                <p>1 Acres Total Project Area</p>
              </div>
            </div>
            <div className="left-sub-content">
              <div className="sub-icon">
                <img src={rera} style={{ width: "40px" }} alt="area" />
              </div>
              <div className="sub-title">
                <p>RERA PROJECT</p>
              </div>
            </div>
            <div className="left-sub-content">
              <div className="sub-icon">
                <img src={villas} style={{ width: "40px" }} alt="area" />
              </div>
              <div className="sub-title">
                <p>14 Floors Luxurious Apartments</p>
              </div>
            </div>
            <div className="stamp">
              <img src={ranavstamp} style={{ width: "150px" }} alt="" />
            </div>
            <div className="foot-title-left">
              <p>LUXUARY APARTMENTS</p>
            </div>
          </div>
        </div>
        <div className="right-content">
          <img src={project5} alt="" />
        </div>
      </div>
      <div className="main-content" id="project6">
        <div className="left-content">
          <div className="">
            <div className="left-logo">
              <img src={ranav_landmark_logo} alt="" />
            </div>
            <div className="left-sub-content">
              <div className="sub-icon">
                <i class="bi bi-geo-fill"></i>
              </div>
              <div className="sub-title">
                <p>
                  GUNJUR,<br></br> WhiteField Bengaluru
                </p>
              </div>
            </div>
            <div className="left-sub-content">
              <div className="sub-icon">
                <img src={area} style={{ width: "30px" }} alt="area" />
              </div>
              <div className="sub-title">
                <p>2.7 Acres Total Project Area</p>
              </div>
            </div>
            <div className="left-sub-content">
              <div className="sub-icon">
                <img src={rera} style={{ width: "40px" }} alt="area" />
              </div>
              <div className="sub-title">
                <p>RERA PROJECT</p>
              </div>
            </div>
            <div className="left-sub-content">
              <div className="sub-icon">
                <img src={villas} style={{ width: "40px" }} alt="area" />
              </div>
              <div className="sub-title">
                <p>33 Floors Luxurious Apartments</p>
              </div>
            </div>
            <div className="stamp">
              <img src={ranavstamp} style={{ width: "150px" }} alt="" />
            </div>
            <div className="foot-title-left">
              <p>LUXUARY APARTMENTS</p>
            </div>
          </div>
        </div>
        <div className="right-content">
          <img src={project6} alt="" />
        </div>
      </div>
      <div className="main-content" id="project7">
        <div className="left-content">
          <div className="">
            <div className="left-logo">
              <img src={tranqul_ranav_logo} alt="" />
            </div>
            <div className="left-sub-content">
              <div className="sub-icon">
                <i class="bi bi-geo-fill"></i>
              </div>
              <div className="sub-title">
                <p>
                  HOSAKOTE,<br></br> WhiteField Bengaluru
                </p>
              </div>
            </div>
            <div className="left-sub-content">
              <div className="sub-icon">
                <img src={area} style={{ width: "30px" }} alt="area" />
              </div>
              <div className="sub-title">
                <p>0.2 Acres Total Project Area</p>
              </div>
            </div>
            <div className="left-sub-content">
              <div className="sub-icon">
                <img src={rera} style={{ width: "40px" }} alt="area" />
              </div>
              <div className="sub-title">
                <p>RERA PROJECT</p>
              </div>
            </div>
            <div className="left-sub-content">
              <div className="sub-icon">
                <img src={villas} style={{ width: "40px" }} alt="area" />
              </div>
              <div className="sub-title">
                <p>9 Floors Luxurious Apartment</p>
              </div>
            </div>
            <div className="stamp">
              <img src={ranavstamp} style={{ width: "150px" }} alt="" />
            </div>
            <div className="foot-title-left">
              <p>LUXUARY APARTMENTS</p>
            </div>
          </div>
        </div>
        <div className="right-content">
          <img src={project7} alt="" />
        </div>
      </div>
      <div className="main-content" id="project8">
        <div className="left-content">
          <div className="">
            <div className="left-logo">
              <img src={akira_ranav_logo} alt="" />
            </div>
            <div className="left-sub-content">
              <div className="sub-icon">
                <i class="bi bi-geo-fill"></i>
              </div>
              <div className="sub-title">
                <p>
                  T CROSS SOUKYA,<br></br> WhiteField Bengaluru
                </p>
              </div>
            </div>
            <div className="left-sub-content">
              <div className="sub-icon">
                <img src={area} style={{ width: "30px" }} alt="area" />
              </div>
              <div className="sub-title">
                <p>2 Acres Total Project Area</p>
              </div>
            </div>
            <div className="left-sub-content">
              <div className="sub-icon">
                <img src={rera} style={{ width: "40px" }} alt="area" />
              </div>
              <div className="sub-title">
                <p>RERA PROJECT</p>
              </div>
            </div>
            <div className="left-sub-content">
              <div className="sub-icon">
                <img src={villas} style={{ width: "40px" }} alt="area" />
              </div>
              <div className="sub-title">
                <p>14 Floors Luxurious Apartment</p>
              </div>
            </div>
            <div className="stamp">
              <img src={ranavstamp} style={{ width: "150px" }} alt="" />
            </div>
            <div className="foot-title-left">
              <p>LUXUARY VILLAS PLOTS</p>
            </div>
          </div>
        </div>
        <div className="right-content">
          <img src={project8} alt="" />
        </div>
      </div>
      <div className="main-content" id="project9">
        <div className="left-content">
          <div className="">
            <div className="left-logo">
              <img src={eco_urbania_ranav_logo} alt="" />
            </div>
            <div className="left-sub-content">
              <div className="sub-icon">
                <i class="bi bi-geo-fill"></i>
              </div>
              <div className="sub-title">
                <p>
                  DEVANAGUNDI,<br></br> OFFWhiteField Bengaluru
                </p>
              </div>
            </div>
            <div className="left-sub-content">
              <div className="sub-icon">
                <img src={area} style={{ width: "30px" }} alt="area" />
              </div>
              <div className="sub-title">
                <p>2.1 Acres Total Project Area</p>
              </div>
            </div>
            <div className="left-sub-content">
              <div className="sub-icon">
                <img src={rera} style={{ width: "40px" }} alt="area" />
              </div>
              <div className="sub-title">
                <p>RERA PROJECT</p>
              </div>
            </div>
            {/* <div className="left-sub-content">
              <div className="sub-icon">
                <img src={villas} style={{ width: "40px" }} alt="area" />
              </div>
              <div className="sub-title">
                <p>35 Luxurious Villas</p>
              </div>
            </div> */}
            <div className="stamp">
              <img src={ranavstamp} style={{ width: "150px" }} alt="" />
            </div>
            <div className="foot-title-left">
              <p>LUXUARY VILLAS PLOTS</p>
            </div>
          </div>
        </div>
        <div className="right-content">
          <img src={project9} alt="" />
        </div>
      </div>

      <Subscription />
      <Footer />
    </>
  );
}
