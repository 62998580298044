import Header from "../components/header";
import Subscription from "../components/subscription";
import Footer from "../components/footer";
// import GalleryTab from "../components/PageComponents/GalleryTab";
import GalleryImg from "../components/PageComponents/GalleryImg";


export default function Gallery(){
    return(
        <>
                <Header/>
                {/* <GalleryTab /> */}
                <GalleryImg />
                <Subscription />
                <Footer />
        </>
    )
}