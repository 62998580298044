import RanavLeader from '../../assets/ranavLeader.png';
export default function LeaderConsulting() {
  return (
    <>
    {/* <div className="banner-space"></div> */}
    <div className='black-bg20'>&nbsp;</div>
    <div className="leader-consulting-container" >
      <div className="image-section">
        <img
          src={RanavLeader}
          alt="Leader Consulting"
          className="leader-image"
        />
        <div className="image-overlay-text">
          {/* <span>P</span> */}
        </div>
      </div>
      <div className="text-section">
        <h2>Leader Consulting</h2>
        <p>
          RANAV Group is defined and inspired by the vision of elevating lifestyles
          through lavish yet affordable living spaces, and increasing productivity
          by building world-class workplaces and commercial centres. In essence,
          providing what the growing Bengaluru needs — exactly where and when it’s needed.
        </p>
        <a href="#" className="read-more-link">Read More&nbsp;<i class="bi bi-arrow-down-circle"></i></a>
      </div>
    </div>
    
    </>
  );
}
