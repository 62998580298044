import Header from "../components/header";
import Footer from "../components/footer";
import ContactNav from "../components/contact-nav";
import Subscription from "../components/subscription";
import AboutContent from "../components/PageComponents/AboutContnet";
import TrustRanav from "../components/PageComponents/TrustRanav";
import FeaturedCategories from "../components/PageComponents/FeaturedCategories";
import Testimonials from "../components/PageComponents/Testimonials"
import BestProperties from "../components/PageComponents/BestProperties"
import MapComponent from "../components/PageComponents/MapComponent";
import LeaderConsulting from "../components/PageComponents/LeaderConsulting";


export default function About(){
    return(
        <>
                <Header/>
                
                <LeaderConsulting />
                <ContactNav />
                <AboutContent />
                <TrustRanav />
                <FeaturedCategories />
                <Testimonials />
                <BestProperties />
                {/* home page body part */}
                <MapComponent />
                <Subscription />
                <Footer />
        </>
    )
}