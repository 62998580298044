import Header from "../components/header";
import Subscription from "../components/subscription";
import Footer from "../components/footer";
import LeaderConsulting from "../components/PageComponents/LeaderConsulting";
// import HomeBanner from "../components/Banners/home_banner";
import LeaderBanner from "../components/Banners/Leader_banner";
import KeyFactorOfRANAV from "../components/PageComponents/KeyFactorOfRANAV";
import RanavTeam from "../components/PageComponents/RanavTeam";

import React, { useState } from "react";


export default function Leaders() {
  return (
    <>
      <Header />
      <LeaderBanner />
      {/* <HomeBanner /> */}
      <LeaderConsulting />
      <KeyFactorOfRANAV />
      <RanavTeam />
      <Subscription />
      <Footer />
    </>
  );
}
